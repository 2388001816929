<template>
  <div class="align window-height-Technologies">
    <div class="section-title fade" v-scrollanimation>
      <h2 class="section-header">Industry 5.0</h2>
    </div>

    <div class="description fade" v-scrollanimation>
      iThermAI is committed to empower manufacturing companies with recent AI
      and computer vision algorithms to increase their digitisation level,
      decrease costs and make them comply with the growing needs of circular
      manufacturing. We convert rich video data into descrete features. Our
      solutions are still under development, stay tuned for more information !
    </div>

    <div v-for="(item, index) in Technologies" :key="index">
      <AppCards
        :direction="item.direction"
        :title="item.title"
        :text="item.text"
        :imageSrcCol="item.imageSrcCol"
        :imageSrcRow="item.imageSrcRow"
      />
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent, reactive } from "vue";
const AppCards = defineAsyncComponent({
  loader: () => import(/* webpackPrefetch: true */ "./AppCards.vue"),
});
export default {
  components: {
    AppCards,
  },
  setup() {
    const Technologies = reactive([
      {
        title: "Digital Twin",
        text: `iThermAI is working on digital twin models of the machines in manufacturing 
      companies. The goal is to build smart predictive maintenance and automated QoS 
      solutions to increase throughput and decrease costs. iThermAI expertise is building 
      efficient deep learning-based AI models for embedded platforms which is highly 
      relevant for manufacturing companies due to privacy and bandwidth reasons. 

      Currently, we are developing video analytics solutions for injection moulding and 
      vacuum infusion machines. Our modules could be conveniently embedded in any 
      digital-twin product as a snap-on feature.`,
        direction: "ltr",
        imageSrcCol: require("@/assets/Tech/digital-twin-col.jpg"),
        imageSrcRow: require("@/assets/Tech/digital-twin-row.jpg"),
      },
      {
        title: "Predictive Maintenance",
        text: `Ever felt that your maintenance scheme is understaffed or inefficient? 
      iThermAI serves any manufacturing company looking for a smart low-cost on-the-edge 
      solution for their precious machines. Using a fusion of advanced RGB and thermal 
      video analytics, sensor network data, and maintenance expert opinion, our models 
      efficiently learn any critical deviation from normal operation and predict the 
      need for maintenance. Our products monitor your machines 24/7 and need minimal 
      human intervention. More details to be provided soon ...`,
        direction: "rtl",
        imageSrcCol: require("@/assets/Tech/predictive-maintenance-col.jpg"),
        imageSrcRow: require("@/assets/Tech/predictive-maintenance-row.jpg"),
      },
    ]);

    return {
      Technologies,
    };
  },
};
</script>

<style scoped>
@import "../styles/globalStyles.scss";

.window-height-Technologies {
  min-height: 50vh;
  padding-bottom: 20px;
}
</style>
